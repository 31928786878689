<template>
  <div
    v-if="loading.created < 2"
    class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 96px)">
    <span class="spinner-border spinner-border">
    </span>
  </div>
<!--  <div :class="loading.created ? null : 'blur'">-->
<!--  <div v-else>-->
  <div v-else>
    <!--  Action Modules  -->
    <FormModule
      v-if="loading.store === 1 || loading.store === 2"
      action="store"
      :columns="data.columns"
      :created="true"
      :loading="loading.store === 2"
      @submitForm="submitForm"
      @closeFormModal="closeFormModal"/>
    <FormModule
      v-if="loading.show === 1 || loading.show === 2"
      action="edit"
      :row="row"
      :columns="data.columns"
      :created="loading.show === 2"
      :loading="loading.update"
      @submitForm="submitForm"
      @closeFormModal="closeFormModal"/>
    <FundsModule
      v-if="loading.funds.show"
      :created="loading.funds.show"
      :loading="loading.funds.update"
      @submitForm="submitForm"
      @closeFormModal="closeFormModal"/>
    <!--  Action Modules end  -->

    <div class="row">
      <div class="col-12">
        <h1 class="view-name">{{ data.title }}</h1>
      </div>
    </div>

    <!--stats module-->
    <StatsModule
      :rows="statsRows"
      @status="(val) => { apiParams.status = val }"/>
    <!--stats module end-->

    <div class="master-view">
      <div class="card border-0 mb-3">

        <!--store action-->
        <button
          v-if="data.actions.includes('store')"
          :title="`add ${data.slug}`"
          @click.prevent="loading.store = 1"
          id="storeAction"
          type="button"
          class="btn btn-custom btn-action storeButton">
          <AddIcon
            @click.prevent="loading.store = 1"
            id="storeActionIcon"
            fill="#ffffff"/>
        </button>
        <!--store action end-->

        <!--status, search, filter module-->
        <div
          v-if="data.config.datatable"
          class="card-header bg-transparent ">
          <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-between w-100">
              <StatusModule
                :rows="data.status"
                @status="(val) => { apiParams.status = val }"/>
              <SearchModule
                :filters="data.filters"
                @search="(val) => { apiParams.search = val }"
                @filter="(val) => { apiParams.filter = val }"/>
            </div>
          </div>
        </div>
        <!--status, search, filter module end-->

        <div class="card-body">
          <div
            v-if="loading.index || rows.length > 0">
            <div class="card-table table-responsive">

              <!-- update status for selected rows -->
              <SelectInput
                v-if="selectedRows.length > 0"
                @change="updateStatusOfSelectedRows($event.target.value)"
                as="select"
                label="Status"
                name="rowsStatus"
                :options="data.status"
                class="updateStatus"/>
              <!-- update status for selected rows end -->

              <div v-if="data.slug === 'email_template'">
                <div class="mt-4">
                  <h5 class="vue-tab-title">Administration Mails</h5>
                  <div class="ms-3 me-3">
                    <ul class="nav nav-tabs" id="myTab">
                      <li class="nav-item">
                        <a href="#admin-account" class="nav-link vue-nav-link active" data-bs-toggle="tab">Auth</a>
                      </li>
                      <li class="nav-item">
                        <a href="#admin-order" class="nav-link vue-nav-link" data-bs-toggle="tab">Order</a>
                      </li>
                      <li class="nav-item">
                        <a href="#admin-fund" class="nav-link vue-nav-link" data-bs-toggle="tab">Fund</a>
                      </li>
                      <li class="nav-item">
                        <a href="#admin-ticket" class="nav-link vue-nav-link" data-bs-toggle="tab">Ticket</a>
                      </li>
                      <li class="nav-item">
                        <a href="#admin-newsletter" class="nav-link vue-nav-link" data-bs-toggle="tab">Newsletter</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane fade show active" id="admin-account">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 1 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="admin-order">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 10 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="admin-fund">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 20 || el.id === 21 || el.id === 22 || el.id === 23 || el.id === 24 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="admin-ticket">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 30 || el.id === 31 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="admin-newsletter">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 150 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <h5 class="vue-tab-title">User Mails</h5>
                  <div class="ms-3 me-3">
                    <ul class="nav nav-tabs" id="myTab">
                      <li class="nav-item">
                        <a href="#user-account" class="nav-link vue-nav-link active" data-bs-toggle="tab">Account</a>
                      </li>
                      <li class="nav-item">
                        <a href="#user-order" class="nav-link vue-nav-link " data-bs-toggle="tab">Order</a>
                      </li>
                      <li class="nav-item">
                        <a href="#user-fund" class="nav-link vue-nav-link" data-bs-toggle="tab">Fund</a>
                      </li>
                      <li class="nav-item">
                        <a href="#user-ticket" class="nav-link vue-nav-link" data-bs-toggle="tab">Ticket</a>
                      </li>
                      <li class="nav-item">
                        <a href="#user-user" class="nav-link vue-nav-link" data-bs-toggle="tab">User</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane fade show active" id="user-account">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 100 || el.id === 101 || el.id === 102 || el.id === 103 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="user-order">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 110 || el.id === 111 || el.id === 112 || el.id === 113 || el.id === 114 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="user-fund">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 120 || el.id === 121 || el.id === 122 || el.id === 123 || el.id === 124 || el.id === 125 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="user-ticket">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 130 || el.id === 131 || el.id === 132 || el.id === 133 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="user-user">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th>
                              Select
                            </th>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.id === 140 || el.id === 141 || el.id === 142 || el.id === 143 })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td>
                              <input
                                v-model="selectedRows"
                                :value="row.id"
                                type="checkbox">
                            </td>
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="data.slug === 'setting/admin'">
                <div class="mt-4">
<!--                  <h5 class="vue-tab-title">Setting</h5>-->
                  <div class="ms-3 me-3">
                    <ul class="nav nav-tabs" id="myTab">
                      <li class="nav-item">
                        <a href="#general" class="nav-link vue-nav-link active" data-bs-toggle="tab">General</a>
                      </li>
                      <li class="nav-item">
                        <a href="#social" class="nav-link vue-nav-link" data-bs-toggle="tab">Social</a>
                      </li>
                      <li class="nav-item">
                        <a href="#mail" class="nav-link vue-nav-link" data-bs-toggle="tab">Mail</a>
                      </li>
                      <li class="nav-item">
                        <a href="#finance" class="nav-link vue-nav-link" data-bs-toggle="tab">Finance</a>
                      </li>
                      <li class="nav-item">
                        <a href="#payment-methods" class="nav-link vue-nav-link" data-bs-toggle="tab">Payment Methods</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane fade show active" id="general">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.name === 'app_name' || el.name === 'app_signature' || el.name === 'app_description' || el.name === 'admin_mail' || el.name === 'dev_app_url' || el.name === 'user_app_url' || el.name === 'admin_app_url' })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="social">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.name === 'social_facebook' || el.name === 'social_youtube' || el.name === 'social_linkedin' || el.name === 'social_twitter' })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="mail">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.name === 'mail_transport' || el.name === 'mail_host' || el.name === 'mail_port' || el.name === 'mail_username' || el.name === 'mail_password' || el.name === 'mail_encryption' || el.name === 'mail_from' })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="finance">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.name === 'commission_percentage' })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="payment-methods">
                        <table class="table align-middle">
                          <thead>
                          <tr>
                            <th
                              v-for="column in visibleColumns"
                              :key="column.slug"
                              :style="[(column.width) ? 'width:'+column.width+';' : null]"
                              scope="col">
                              {{ column.label }}
                            </th>
                          </tr>
                          </thead>
                          <tbody
                            v-if="loading.index">
                          <tr
                            v-for="row in parseInt(apiParams.perPage)"
                            :key="row">
                            <LoadingModule
                              v-for="column in visibleColumns"
                              :key="column.slug"/>
                          </tr>
                          </tbody>
                          <tbody
                            v-if="!loading.index">
                          <tr
                            v-for="row in rows.filter(el => { return el.name === 'stripe' || el.name === 'paypal' || el.name === 'credit_card' })"
                            :key="row.id"
                            @dblclick="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                            class="cursor-pointer"
                          >
                            <td
                              v-for="column in visibleColumns"
                              :key="column.slug">
                              <div v-if="column.type === 'action'">
                                <div class="btn-group dropstart">
                                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                  </div>
                                  <ul class="dropdown-menu">
                                    <li
                                      v-for="action in column.actions"
                                      :key="action.id"
                                      class="cursor-pointer">
                                      <a
                                        v-if="action.slug === 'edit'"
                                        @click="show(getColumnBySlug(row, 'id'))"
                                        class="dropdown-item">
                                        {{ action.label }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div v-else-if="column.type === 'status'">
                                <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                              </div>
                              <div v-else>
                                {{ getColumnBySlug(row, column.slug) }}
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table
                v-else
                class="table align-middle">
                <thead>
                <tr>
                  <th v-if="data.status">
                    <input
                      v-model="selectedRowsAll"
                      type="checkbox">
                  </th>
                  <th
                    v-for="column in visibleColumns"
                    :key="column.slug"
                    :style="[(column.width) ? 'width:'+column.width+';' : null]"
                    scope="col">
                    {{ column.label }}
                  </th>
                </tr>
                </thead>
                <tbody
                  v-if="loading.index">
                <tr
                  v-for="row in parseInt(apiParams.perPage)"
                  :key="row">
                  <LoadingModule
                    v-for="column in visibleColumns"
                    :key="column.slug"/>
                </tr>
                </tbody>
                <tbody
                  v-if="!loading.index">
                <tr
                  v-for="row in rows"
                  :key="row.id"
                  class="cursor-pointer"
                >
                  <td v-if="data.status">
                    <input
                      v-model="selectedRows"
                      :value="row.id"
                      type="checkbox">
                  </td>
                  <td
                    v-for="column in visibleColumns"
                    :key="column.slug"
                    @click="column.editable ? singleShow(row) : null">
                    <div v-if="column.type === 'action'">
                      <div class="btn-group dropstart">
                        <svg
                          class="me-2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                        </svg>
                        <ul class="dropdown-menu">
                          <li
                            v-for="action in column.actions"
                            :key="action.id"
                            class="cursor-pointer">
                            <router-link
                              v-if="action.slug === 'open-ticket'"
                              :to="{ name: 'ticket.store.params', params: { user: row.user.id, uid: row.uid } }"
                              class="dropdown-item">
                              {{ action.label }}
                            </router-link>
                            <router-link
                              v-if="action.slug === 'reply'"
                              :to="{ name: 'ticket.show', params: { id: row.id } }"
                              class="dropdown-item">
                              {{ action.label }}
                            </router-link>
                            <a
                              v-if="action.slug === 'funds'"
                              @click="fundsShow(getColumnBySlug(row, 'id'))"
                              class="dropdown-item ignore-outside-click">
                              {{ action.label }}
                            </a>
                            <a
                              v-if="action.slug === 'edit'"
                              @click="show(getColumnBySlug(row, 'id'))"
                              class="dropdown-item">
                              {{ action.label }}
                            </a>
                            <a
                              v-if="action.slug === 'delete'"
                              @click="destroy(getColumnBySlug(row, 'id'))"
                              class="dropdown-item">
                              {{ action.label }}
                            </a>
                            <a
                              v-if="action.slug === 'login-as-user'"
                              @click="loginAsUser(getColumnBySlug(row, 'email'))"
                              class="dropdown-item">
                              {{ action.label }}
                            </a>
                            <router-link
                              v-if="action.slug === 'affiliates'"
                              :to="{ name: 'user.affiliates', params: { id: row.id } }"
                              class="dropdown-item">
                              {{ action.label }}
                            </router-link>
                          </li>
                        </ul>
                        <svg
                          @click="isEditable ? show(getColumnBySlug(row, 'id')) : null"
                          width="24" height="24"
                          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-square-edit-outline" viewBox="0 0 24 24">
                          <path d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z"></path>
                        </svg>
                      </div>
                    </div>
                    <div v-else-if="column.type === 'date'">
                      {{ timestampToDate(getColumnBySlug(row, column.slug)) }}
                    </div>
                    <div v-else-if="column.type === 'time24'">
                      {{ timestampToTime24(getColumnBySlug(row, column.slug)) }}
                    </div>
                    <div v-else-if="column.type === 'price'">
                      {{ centsToDollar(getColumnBySlug(row, column.slug)) }}
                    </div>
                    <div v-else-if="column.type === 'status'">
                      <StatusButton :status="getColumnBySlug(row, column.slug)"/>
                    </div>
                    <div v-else-if="column.slug === 'url'">
                      <a :href="getColumnBySlug(row, column.slug, false)" target="_blank">{{ getColumnBySlug(row, column.slug) }}</a>
                    </div>
                    <div v-else>
                      <span
                        v-if="column.editable"
                        style="font-size: 13px">
                        {{ getColumnBySlug(row, column.slug) }}
                      </span>
                      <div v-else>
                        {{ getColumnBySlug(row, column.slug) }}
                      </div>

                      <input
                        v-if="column.editable && this.row !== null && row.id === this.row.id"
                        @keyup.enter="singleUpdate(row.id, column.slug, $event.target.value)"
                        type="number"
                        name=""
                        id=""
                        class="ms-3 w-50">
                    </div>

                    <div v-if="column.data !== undefined">
                      <span  v-if="column.data.type === 'date'">
                        {{ timestampToDate(getColumnBySlug(row, column.data.slug)) }}
                      </span>
                      <span  v-else-if="column.data.type === 'time24'">
                        {{ timestampToTime24(getColumnBySlug(row, column.data.slug)) }}
                      </span>
                      <span  v-else>
                        {{ getColumnBySlug(row, column.data.slug) }}
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <PaginationModule
              v-if="data.config.datatable"
              :pagination="pagination"
              @prev="index(apiParams, pagination.prevPageUrl)"
              @next="index(apiParams, pagination.nextPageUrl)"
              @perPage="(val) => { apiParams.perPage = val }"
              class="card-pagination"/>

          </div>
          <div
            v-else
            class="d-flex justify-content-center align-items-center" style="min-height: 373px;">
            <h5>No data found</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseUrl from '@/config/baseUrl.config'
import authHeader from '@/config/authHeader.config'
import axios from 'axios'
import StatusButton from '@/components/Page/Assets/StatusButton'
import PaginationModule from '@/components/Page/Modules/PaginationModule'
import StatusModule from '@/components/Page/Modules/StatusModule'
import LoadingModule from '@/components/Page/Modules/LoadingModule'
import SearchModule from '@/components/Page/Modules/SearchModule'
import FormModule from '@/components/Page/Modules/FormModule'
import StatsModule from '@/components/Page/Modules/StatsModule'
import AddIcon from '@/components/Svg/AddIcon'
import SelectInput from '@/components/Page/Modules/Inputs/SelectInput'
import FundsModule from '@/components/Page/Modules/FundsModule'

export default {
  name: 'MasterComponent',
  components: {
    FundsModule,
    SelectInput,
    AddIcon,
    StatsModule,
    FormModule,
    SearchModule,
    LoadingModule,
    StatusModule,
    PaginationModule,
    StatusButton
  },
  /*
  * data.column.type
  * timestamp, price, status
  */
  props: [
    'data'
  ],
  created () {
    this.createComponent()
  },
  data () {
    return {
      loading: {
        created: 1,
        index: false,
        store: 0,
        show: 0,
        update: false,
        destroy: false,
        status: false,
        funds: {
          show: false,
          update: false
        },
        loginAsUser: false
      },
      apiBaseUrl: baseUrl().api + this.data.slug,
      apiParams: {
        perPage: 5,
        draw: 0,
        filter: null,
        search: null,
        status: null
      },
      pagination: {
        lastPage: '',
        currentPage: '',
        total: '',
        lastPageUrl: '',
        nextPageUrl: '',
        prevPageUrl: '',
        from: '',
        to: ''
      },
      selectedRowsAll: false,
      selectedRows: [],
      statsRows: [],
      rows: [],
      row: null
    }
  },
  methods: {
    stats () {
      return axios
        .get(this.apiBaseUrl + '/stats/admin', {
          headers: authHeader()
        })
        .then(res => {
          this.statsRows = res.data
          this.loading.created++
          console.log(res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    index (params, url) {
      this.loading.index = true
      return axios
        .get(url, {
          headers: authHeader(),
          params: params
        })
        .then(res => {
          if (this.data.config.datatable) {
            this.rows = res.data.data
            this.configPagination(res.data)
            this.configPagination(res.data)
          } else {
            this.rows = res.data
          }

          this.loading.created++
          this.loading.index = false
          console.log(res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err)
          // return Promise.reject(err.response.data)
        })
    },
    show (id) {
      this.loading.show = 1
      return axios
        .get(this.apiBaseUrl + '/' + id, {
          headers: authHeader()
        })
        .then(res => {
          this.row = res.data
          this.loading.show = 2
          console.log(res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    store (data) {
      this.loading.store = 2
      return axios
        .post(this.apiBaseUrl, data, {
          headers: authHeader()
        })
        .then(res => {
          this.syncRowInRows(null, res.data)
          this.syncRowInStatsRows(
            null,
            null,
            this.getRowIndexByRowSlugFromStatsRows(res.data.status),
            res.data.status
          )
          this.loading.store = 0
          console.log(res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          this.loading.store = 1
          return Promise.reject(err)
        })
    },
    update (id, data) {
      this.loading.update = true
      return axios
        .post(this.apiBaseUrl + '/' + id, data, {
          headers: authHeader()
        })
        .then(res => {
          this.syncRowInRows(this.getRowIndexByRowIdFromRows(this.row.id), res.data)
          this.syncRowInStatsRows(
            this.getRowIndexByRowSlugFromStatsRows(this.row.status),
            this.row.status,
            this.getRowIndexByRowSlugFromStatsRows(res.data.status),
            res.data.status
          )
          this.loading.show = 0
          this.loading.update = false
          console.log(res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          this.$alert(
            err.response.data.message,
            'Error!',
            'error'
          )
          this.loading.update = false
          return Promise.reject(err.response)
        })
    },
    destroy (id) {
      this.$fire({
        title: 'Are you sure?',
        type: 'info',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading.destroy = true
          return axios
            .delete(this.apiBaseUrl + '/' + id, {
              headers: authHeader()
            })
            .then(() => {
              this.loading.destroy = false
              this.createComponent()
              return true
            }).catch((err) => {
              this.$alert(
                `Request failed: ${err}`,
                'Error!',
                'error'
              )
            })
        },
        allowOutsideClick: false
      }).then((res) => {
        if (res.value) {
          this.$alert(
            'Selected row has has been deleted.',
            'Deleted!',
            'success'
          )
        }
      })
    },
    fundsUpdate (id, data) {
      this.loading.funds.update = true
      return axios
        .post(this.apiBaseUrl + '/' + id + '/funds', data, {
          headers: authHeader()
        })
        .then(res => {
          this.syncRowInRows(this.getRowIndexByRowIdFromRows(id), res.data.cash, 'cash')
          this.loading.funds.update = false
          this.loading.funds.show = false
          console.log(res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    fundsShow (id) {
      this.row = id
      this.loading.funds.show = true
    },
    statusUpdate (data) {
      this.loading.status = true
      return axios
        .post(this.apiBaseUrl + '/status', data, {
          headers: authHeader()
        })
        .then(res => {
          const status = this.getStatusById(data.status)
          this.selectedRows.forEach(id => {
            const row = JSON.parse(JSON.stringify(this.getRowByIdFromRows(id)))

            if (status.slug !== 'cancelled' && row.status === 'cancelled') {
              return
            } else if (status.slug === 'cancelled' && row.status !== 'cancelled') {
              this.syncRowInRows(this.getRowIndexByRowIdFromRows(id), 0, 'cost')
            }

            this.syncRowInRows(this.getRowIndexByRowIdFromRows(id), status.slug, 'status')
            this.syncRowInStatsRows(
              this.getRowIndexByRowSlugFromStatsRows(row.status),
              row.status,
              this.getRowIndexByRowSlugFromStatsRows(status.slug),
              status.slug
            )
          })
          this.selectedRowsAll = false
          this.selectedRows = []
          this.loading.status = false
          console.log(res)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    loginAsUser (email) {
      this.loading.loginAsUser = true
      return axios
        .post(this.apiBaseUrl + '/login', {
          email: email
        }, {
          headers: authHeader()
        })
        .then(res => {
          this.loading.loginAsUser = false
          window.open(`${res.data.redirect_url}login-as-user/${res.data.user_id}/${res.data.access_token}`, '_blank')
          console.log(res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    singleShow (row) {
      this.row = row
      // if (this.row === null || this.row.id !== row.id) {
      //   this.row = row
      // } else {
      //   this.row = null
      // }
    },
    singleUpdate (id, column, value) {
      const data = {
        _method: 'put',
        [column]: value
      }

      return axios
        .post(this.apiBaseUrl + '/' + id, data, {
          headers: authHeader()
        })
        .then(res => {
          this.syncRowInRows(this.getRowIndexByRowIdFromRows(id), res.data)
          this.row = null
          // this.$alert(
          //   'Selected row has been updated successfully.',
          //   'Updated!',
          //   'success'
          // )
          return Promise.resolve(res.data)
        })
        .catch(err => {
          this.row = null
          this.$alert(
            err.response.data.message,
            'Error!',
            'error'
          )
          return Promise.reject(err.response)
        })
    },

    /* methods */
    createComponent () {
      this.loading.created = 0
      this.index(this.apiParams, this.apiBaseUrl)
      if (this.data.config.stats) {
        this.stats()
      } else {
        this.loading.created++
      }
    },
    submitForm (formData) {
      console.log('submitForm (formData)', formData)
      /*
      * Fix cost as per dollar with cents
      */
      if (formData.code !== undefined && formData.min !== undefined) {
        formData.min = formData.min * (10 ** 2)
        formData.min = parseInt(formData.min)
      }
      if (formData.code !== undefined && formData.max !== undefined) {
        formData.max = formData.max * (10 ** 2)
        formData.max = parseInt(formData.max)
      }
      if (formData.funds !== undefined) {
        formData.funds = formData.funds * (10 ** 2)
        formData.funds = parseInt(formData.funds)
      }
      if (formData.cost !== undefined) {
        if (formData.interval !== undefined) {
          formData.cost = (formData.cost / formData.interval) * (10 ** 2)
          formData.cost = parseInt(formData.cost)
        } else {
          formData.cost = formData.cost * (10 ** 2)
          formData.cost = parseInt(formData.cost)
        }
      }

      if (formData.addons !== undefined && formData.addons !== null) {
        const addonsArray = []
        formData.addons.forEach((option) => {
          addonsArray.push(option.id)
        })

        formData.addons = addonsArray
        console.log(Object.prototype.toString.call(addonsArray))
        console.log(Object.prototype.toString.call(formData.addons))
      }

      if (formData.users !== undefined && formData.users !== null) {
        const usersArray = []
        formData.users.forEach((option) => {
          usersArray.push(option.id)
        })

        formData.users = usersArray
        console.log(Object.prototype.toString.call(usersArray))
        console.log(Object.prototype.toString.call(formData.users))
      }

      if (formData.services !== undefined && formData.services !== null) {
        const servicesArray = []
        formData.services.forEach((option) => {
          servicesArray.push(option.id)
        })

        formData.services = servicesArray
        console.log(Object.prototype.toString.call(servicesArray))
        console.log(Object.prototype.toString.call(formData.services))
      }

      if (this.loading.show === 2) {
        formData._method = 'put'
        this.update(this.row.id, formData)
      }
      if (this.loading.funds.show) {
        this.fundsUpdate(this.row, formData)
      }
      if (this.loading.store === 1) {
        this.store(formData)
      }
      console.log('submitForm (formData) > modified', formData)
    },
    closeFormModal () {
      if (this.loading.show === 2) {
        this.row = null
        this.loading.show = 0
      }
      if (this.loading.store === 1) {
        this.row = null
        this.loading.store = 0
      }
      if (this.loading.funds.show) {
        this.row = null
        this.loading.funds.show = false
        this.loading.funds.update = false
      }
      console.log('closed')
    },
    updateStatusOfSelectedRows (statusID) {
      this.$fire({
        title: 'Are you sure?',
        type: 'info',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.statusUpdate({
            status: statusID,
            rows: this.selectedRows
          }).then(() => {
            return true
          }).catch((err) => {
            this.$alert(
              `Request failed: ${err}`,
              'Error!',
              'error'
            )
          })
        },
        allowOutsideClick: false
      }).then((res) => {
        if (res.value) {
          this.$alert(
            'Selected rows status has been updated.',
            'Updated!',
            'success'
          )
        }
      })
    },

    /* helpers */
    configPagination (data) {
      this.pagination.lastPage = data.meta.last_page
      this.pagination.currentPage = data.meta.current_page
      this.pagination.total = data.meta.total
      this.pagination.lastPageUrl = data.links.last
      this.pagination.nextPageUrl = data.links.next
      this.pagination.prevPageUrl = data.links.prev
      this.pagination.from = data.meta.from
      this.pagination.to = data.meta.to
    },
    getColumnBySlug (row, slug, limit = true) {
      let columnData = null
      if (typeof slug === 'string') {
        if (slug === 'mailable') {
          columnData = row[slug].split('\\')[row[slug].split('\\').length - 1]
        } else {
          columnData = row[slug]
        }
      } else {
        switch (slug.length) {
          case 1:
            columnData = row[slug[0]]
            break
          case 2:
            columnData = (row[slug[0]] !== null) ? row[slug[0]][slug[1]] : null
            break
          case 3:
            columnData = (row[slug[0]] !== null) ? (row[slug[0]][slug[1]] !== null) ? row[slug[0]][slug[1]][slug[2]] : null : null
            break
        }
      }

      if (columnData && columnData.length > 40 && limit) {
        columnData = columnData.substring(0, 40) + '. . .'
      }
      return columnData
    },
    getRowIndexByRowIdFromRows (rowId) {
      return this.rows.findIndex((row) => {
        return row.id === rowId
      })
    },
    syncRowInRows (rowIndex, rowData, rowColumn = null) {
      if (rowIndex === null) {
        this.rows.unshift(rowData)
      } else if (rowColumn !== null) {
        this.rows[rowIndex][rowColumn] = rowData
      } else {
        this.rows[rowIndex] = rowData
      }
    },
    getRowIndexByRowSlugFromStatsRows (rowSlug) {
      return this.statsRows.findIndex((row) => {
        return row.slug === rowSlug
      })
    },
    syncRowInStatsRows (oldStatusIndex, oldStatus, newStatusIndex, newStatus) {
      if (oldStatusIndex === null && oldStatus === null) {
        if (newStatusIndex !== -1) {
          this.statsRows[newStatusIndex].value++
        }
      } else if (oldStatus !== newStatus) {
        if (oldStatusIndex !== -1) {
          this.statsRows[oldStatusIndex].value--
        }
        if (newStatusIndex !== -1) {
          this.statsRows[newStatusIndex].value++
        }
      }
      this.row = null
    },
    getRowByIdFromRows (id) {
      return this.rows.find((row) => {
        return row.id === parseInt(id)
      })
    },
    getStatusById (id) {
      return this.data.status.find((status) => {
        return status.id === parseInt(id)
      })
    },

    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    },
    timestampToDate (timestamp) {
      return this.$timestampToDate(timestamp)
    },
    timestampToTime24 (timestamp) {
      return this.$timestampToTime24(timestamp)
    }
  },
  computed: {
    isEditable () {
      const actionColumn = this.data.columns.find((column) => {
        return column.type === 'action'
      })
      const editAction = actionColumn.actions.find((action) => {
        return action.slug === 'edit'
      })

      return editAction !== undefined
    },
    visibleColumns () {
      return this.data.columns.filter((column) => {
        return column.display === undefined || column.display !== false
      })
    }
  },
  watch: {
    'apiParams.perPage': function (val, oldVal) {
      this.index(this.apiParams, this.apiBaseUrl)
    },
    'apiParams.status': function (val, oldVal) {
      this.index(this.apiParams, this.apiBaseUrl)
    },
    'apiParams.filter': function (val, oldVal) {
      if (val === '') {
        this.apiParams.filter = null
        return
      }
      this.index(this.apiParams, this.apiBaseUrl)
    },
    'apiParams.search': function (val, oldVal) {
      if (val === '') {
        this.apiParams.search = null
        return
      }
      this.index(this.apiParams, this.apiBaseUrl)
    },
    selectedRowsAll: function (val, oldVal) {
      if (val === true) {
        this.selectedRows = []
        this.rows.forEach(row => {
          this.selectedRows.push(row.id)
        })
      } else {
        this.selectedRows = []
      }
    }
  }
}
</script>

<style scoped>
select.filter:active,
select.filter:focus {
  outline: unset;
  box-shadow: unset;
}

.storeButton {
  position: absolute;
  right: -1px;
  top: -1px;
  width: 40px;
  border-radius: unset;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  transition: 0.3s;
}

.storeButton:hover {
  width: 70px;
  background-color: var(--purple-default);
}

.updateStatus {
  width: 200px;
}
</style>
