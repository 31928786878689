<template>
  <!-- Add, Edit, Delete -->
  <MasterComponent
    :data="data" />
</template>

<script>
import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'
export default {
  name: 'AnnouncementView',
  components: {
    MasterComponent
  },
  data () {
    const feature = [
      {
        id: 1,
        label: 'Feature',
        slug: 'feature'
      },
      {
        id: 0,
        label: 'Un-feature',
        slug: 'unFeature'
      }
    ]
    const status = [
      {
        id: null,
        label: 'All',
        slug: 'all'
      },
      {
        id: 1,
        label: 'Publish',
        slug: 'enable'
      },
      {
        id: 0,
        label: 'Un-publish',
        slug: 'disable'
      }
    ]
    return {
      data: {
        title: 'Announcements',
        slug: 'announcement',
        config: {
          stats: false,
          datatable: false
        },
        status: status,
        actions: [
          'store',
          'edit',
          'delete'
        ],
        columns: [
          {
            label: 'ID',
            slug: 'id',
            type: 'text'
          },
          {
            label: 'Title',
            slug: 'title',
            type: 'text',
            field: {
              name: 'title',
              as: 'input',
              rules: Yup.string().required('Title is a required field').nullable()
            }
          },
          {
            label: 'Message',
            slug: 'message',
            type: 'text',
            field: {
              name: 'message',
              as: 'textarea',
              rules: Yup.string().required('Message is a required field').nullable()
            }
          },
          {
            label: 'Feature',
            slug: 'feature',
            width: '80px',
            type: 'status',
            field: {
              name: 'feature',
              slug: 'feature',
              as: 'select',
              options: feature,
              rules: Yup.number().nullable()
            }
          },
          {
            label: 'Status',
            slug: 'status',
            width: '80px',
            type: 'status',
            field: {
              name: 'status',
              slug: 'status',
              as: 'select',
              options: status,
              rules: Yup.number().nullable()
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: 'Edit',
                slug: 'edit'
              },
              {
                id: 2,
                label: 'Delete',
                slug: 'delete'
              }
            ]
          }
        ]
      },
      activeTab: 'All',
      searchBox: false
    }
  }
}
</script>
