<template>
  <!-- Add, Edit, Delete -->
  <MasterComponent
    :data="data" />
</template>

<script>
import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'
import axios from 'axios'
import baseUrl from '@/config/baseUrl.config'
import authHeader from '@/config/authHeader.config'
export default {
  name: 'CategoriesView',
  components: {
    MasterComponent
  },
  created () {
    axios
      .get(baseUrl().api + 'category', {
        headers: authHeader()
      })
      .then(res => {
        this.data.columns[2].field.options = res.data
      })
      .catch(err => {
        return Promise.reject(err.response.data)
      })
    // axios
    //   .get(baseUrl().api + 'service', {
    //     headers: authHeader()
    //   })
    //   .then(res => {
    //     this.data.columns[14].field.options = res.data
    //   })
    //   .catch(err => {
    //     return Promise.reject(err.response.data)
    //   })
  },
  data () {
    const status = [
      {
        id: null,
        label: 'All',
        slug: 'all'
      },
      {
        id: 1,
        label: 'Publish',
        slug: 'enable'
      },
      {
        id: 0,
        label: 'Un-publish',
        slug: 'disable'
      }
    ]
    return {
      data: {
        title: 'Services',
        slug: 'service',
        config: {
          stats: false,
          datatable: false
        },
        status: status,
        actions: [
          'store',
          'edit',
          'delete'
        ],
        columns: [
          {
            label: 'ID',
            slug: 'id',
            type: 'text'
          },
          {
            label: 'Service',
            slug: 'name',
            type: 'text',
            field: {
              class: 'col-12',
              name: 'name',
              as: 'input',
              type: 'text',
              rules: Yup.string().required('Service is a required field').nullable()
            }
          },
          {
            label: 'Category',
            slug: [
              'category',
              'name'
            ],
            type: 'text',
            field: {
              class: 'col-6',
              name: 'category_id',
              slug: [
                'category',
                'id'
              ],
              as: 'select',
              options: [],
              rules: Yup.number().required('Category is a required field').nullable()
            }
          },
          {
            label: 'Total Addons',
            slug: 'total_addons',
            type: 'text'
          },
          {
            label: 'Total Orders',
            slug: 'total_orders',
            type: 'text'
          },
          {
            label: 'Total Revenue',
            slug: 'total_revenue',
            type: 'price'
          },
          {
            label: 'Status',
            slug: 'status',
            width: '80px',
            type: 'status',
            field: {
              class: 'col-6',
              name: 'status',
              slug: 'status',
              as: 'select',
              options: status,
              rules: Yup.number().required('Status is a required field').nullable()
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: 'Edit',
                slug: 'edit'
              },
              {
                id: 2,
                label: 'Delete',
                slug: 'delete'
              }
            ]
          },

          /* hidden columns for form build */
          {
            display: false,
            label: 'Min',
            slug: 'min',
            type: 'number',
            field: {
              class: 'col-3',
              name: 'min',
              as: 'input',
              type: 'number',
              min: '0',
              rules: Yup.number().required('Min is a required field').min(1).nullable()
            }
          },
          {
            display: false,
            label: 'Max',
            slug: 'max',
            type: 'number',
            field: {
              class: 'col-3',
              name: 'max',
              as: 'input',
              type: 'number',
              min: '0',
              rules: Yup.number().required('Max is a required field').min(1).nullable()
            }
          },
          {
            display: false,
            label: 'Interval',
            slug: 'interval',
            type: 'number',
            field: {
              class: 'col-3',
              name: 'interval',
              as: 'input',
              type: 'number',
              min: '0',
              rules: Yup.number().required('Interval is a required field').min(1).nullable()
            }
          },
          {
            display: false,
            label: 'Cost',
            slug: 'cost_per_interval',
            type: 'price',
            field: {
              class: 'col-3',
              name: 'cost',
              as: 'input',
              type: 'number',
              min: '0',
              step: '.01',
              rules: Yup.number().required('Cost is a required field').min(1).nullable()
            }
          },
          {
            display: false,
            label: 'Description',
            slug: 'description',
            type: 'text',
            field: {
              name: 'description',
              as: 'textarea',
              rules: Yup.string().nullable()
            }
          },
          {
            display: false,
            label: 'Notes',
            slug: 'notes',
            type: 'text',
            field: {
              name: 'notes',
              as: 'textarea',
              rules: Yup.string().required('Notes is a required field').nullable()
            }
          },
          {
            display: false,
            label: 'Addons',
            slug: 'addons',
            type: 'text',
            field: {
              name: 'addons',
              slug: 'addons',
              as: 'multi-select',
              module: 'service',
              pagination: false,
              searchBy: 'name',
              rules: Yup.array().nullable()
            }
          }
        ]
      },
      activeTab: 'All',
      searchBox: false
    }
  }
}
</script>
