<template>
  <div class="vue-modal">
    <div
      v-if="!created"
      class="d-flex justify-content-center align-items-center h-100">
      <span class="spinner-border spinner-border">
      </span>
    </div>
    <div
      v-if="created"
      class="vue-modal-content">
      <span
        @click.prevent="$emit('closeFormModal')"
        class="vue-modal-close">&times;</span>
      <Form
        @submit="submitForm">
        <div class="row">
          <div
            v-for="column in editableColumns" :key="column.field.name"
            :class="column.field.class"
            class="mb-4">
            <SimpleInput
              v-if="column.field.as === 'input'"
              :rules="column.field.rules"
              :as="column.field.as"
              :label="column.label"
              :name="column.field.name"
              :type="column.field.type"
              :min="(column.field.min !== undefined) ? column.field.min : null"
              :max="(column.field.max !== undefined) ? column.field.max : null"
              :step="(column.field.step !== undefined) ? column.field.step : null"
              :modelValue="action === 'edit' ? (column.field.step !== undefined && column.field.step === '.01') ? (getColumnBySlug(row, column.slug) / (10 ** 2)) : getColumnBySlug(row, column.slug) : null"/>

            <TextareaInput
              v-if="column.field.as === 'textarea'"
              :rules="column.field.rules"
              :as="column.field.as"
              :label="column.label"
              :name="column.field.name"
              :modelValue="action === 'edit' ? getColumnBySlug(row, column.slug) : null"/>

            <SelectInput
              v-if="column.field.as === 'select' && (column.field.slug === 'status' || column.field.slug === 'feature'  || column.field.slug === 'trail' || column.field.slug === 'priority' || column.field.slug === 'discount_type' || column.field.slug === 'usage_type')"
              :rules="column.field.rules"
              :as="column.field.as"
              :label="column.label"
              :name="column.field.name"
              :options="column.field.options"
              :modelValue="action === 'edit' ? column.field.options.find((option) => { return option.slug === getColumnBySlug(row, column.field.slug) }).id : null"/>
            <SelectInput
              v-else-if="column.field.as === 'select'"
              :rules="column.field.rules"
              :as="column.field.as"
              :label="column.label"
              :name="column.field.name"
              :options="column.field.options"
              :modelValue="action === 'edit' ? getColumnBySlug(row, column.field.slug) : null"/>
            <SelectMultipleInput
              v-else-if="column.field.as === 'multi-select'"
              :rules="column.field.rules"
              :as="column.field.as"
              :label="column.label"
              :name="column.field.name"
              :module="column.field.module"
              :pagination="column.field.pagination"
              :searchBy="column.field.searchBy"
              :modelValue="action === 'edit' ? getColumnBySlug(row, column.field.slug) : null"/>
            <HtmlTextareaInput
              v-if="column.field.as === 'html-textarea'"
              :rules="column.field.rules"
              :as="column.field.as"
              :label="column.label"
              :name="column.field.name"
              :modelValue="action === 'edit' ? getColumnBySlug(row, column.slug) : null"/>
            <RichTextEditor
              v-else-if="column.field.as === 'rich-text'"
              :rules="column.field.rules"
              :as="column.field.as"
              :label="column.label"
              :name="column.field.name"
              :modelValue="action === 'edit' ? getColumnBySlug(row, column.slug) : null"/>
          </div>
        </div>
        <button class="btn btn-primary w-100 mb-4">
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm">
        </span>
          <span v-else>
          Save
        </span>
        </button>
<!--        <button-->
<!--          @click.prevent="$emit('closeFormModal')"-->
<!--          class="btn btn-secondary w-100">-->
<!--          Close-->
<!--        </button>-->
      </Form>
    </div>
  </div>
</template>

<script>
import { Form } from 'vee-validate'
import SimpleInput from '@/components/Page/Modules/Inputs/SimpleInput'
import SelectInput from '@/components/Page/Modules/Inputs/SelectInput'
import TextareaInput from '@/components/Page/Modules/Inputs/TextareaInput'
import SelectMultipleInput from '@/components/Page/Modules/Inputs/SelectMultipleInput'
import RichTextEditor from '@/components/Page/Modules/Inputs/RichTextEditor'
import HtmlTextareaInput from '@/components/Page/Modules/Inputs/HtmlTextareaInput'

export default {
  name: 'FormModule',
  components: {
    HtmlTextareaInput,
    RichTextEditor,
    SelectMultipleInput,
    TextareaInput,
    SelectInput,
    SimpleInput,
    Form
  },
  props: ['action', 'row', 'columns', 'created', 'loading'],
  methods: {
    submitForm (formData) {
      this.$emit('submitForm', formData)
    },

    /* helpers */
    getColumnBySlug (row, slug) {
      if (typeof slug === 'string') {
        return row[slug]
      } else {
        switch (slug.length) {
          case 1:
            return row[slug[0]]
          case 2:
            return row[slug[0]][slug[1]]
          case 3:
            return row[slug[0]][slug[1]][slug[2]]
        }
      }
    }
  },
  computed: {
    editableColumns () {
      return this.columns.filter((column) => {
        return column.field !== undefined
      })
    }
  }
}
</script>

<style scoped>
.vue-modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.vue-modal .vue-modal-content {
  background-color: #fefefe;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
}

.vue-modal .vue-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  line-height: 20px;
  font-weight: bold;
}

.vue-modal .vue-modal-close:hover,
.vue-modal .vue-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
