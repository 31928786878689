<template>
  <!-- Add, Edit, Delete -->
  <MasterComponent
    :data="data" />
</template>

<script>
import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'
export default {
  name: 'CategoriesView',
  components: {
    MasterComponent
  },
  data () {
    const status = [
      {
        id: null,
        label: 'All',
        slug: 'all'
      },
      {
        id: 1,
        label: 'Publish',
        slug: 'enable'
      },
      {
        id: 0,
        label: 'Un-publish',
        slug: 'disable'
      }
    ]
    const trail = [
      {
        id: 1,
        label: 'Trail',
        slug: 'trail'
      },
      {
        id: 0,
        label: 'Paid',
        slug: 'paid'
      }
    ]
    return {
      data: {
        title: 'Categories',
        slug: 'category',
        config: {
          stats: false,
          datatable: false
        },
        status: status,
        actions: [
          'store',
          'edit',
          'delete'
        ],
        columns: [
          {
            label: 'ID',
            slug: 'id',
            type: 'text'
          },
          {
            label: 'Category',
            slug: 'name',
            type: 'text',
            field: {
              name: 'name',
              as: 'input',
              type: 'text',
              rules: Yup.string().required('Category is a required field').nullable()
            }
          },
          {
            label: 'Total Services',
            slug: 'total_services',
            type: 'text'
          },
          {
            label: 'Total Orders',
            slug: 'total_orders',
            type: 'text'
          },
          {
            label: 'Total Revenue',
            slug: 'total_revenue',
            type: 'price'
          },
          {
            label: 'Status',
            slug: 'status',
            width: '80px',
            type: 'status',
            field: {
              class: 'col-6',
              name: 'status',
              slug: 'status',
              as: 'select',
              options: status,
              rules: Yup.number().required('Status is a required field').nullable()
            }
          },
          {
            label: 'Trail',
            slug: 'trail',
            width: '80px',
            type: 'status',
            field: {
              class: 'col-6',
              name: 'trail',
              slug: 'trail',
              as: 'select',
              options: trail,
              rules: Yup.number().nullable()
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: 'Edit',
                slug: 'edit'
              },
              {
                id: 2,
                label: 'Delete',
                slug: 'delete'
              }
            ]
          }
        ]
      },
      activeTab: 'All',
      searchBox: false
    }
  }
}
</script>
